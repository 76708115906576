"use strict";
/*!
 *
 * app.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
// import 'polyfill';
// global.Promise = require('es6-promise').Promise;
require("picturefill");
/*** node_modules **/
require("jquery.easing");
require("slick-carousel");
require("contents");
require("@fancyapps/fancybox");
// import 'fancy-defaults';
// $(window).on('scroll', function() {
//   if($(window).scrollTop() > 20) {
//     $('.site-header').addClass('-scrolled');
//   } else {
//     $('.site-header').removeClass('-scrolled');
//   }
// });
$(function () {
    var $btn = $('.menu__btn');
    var $nav = $('.menu__main');
    var $header = $('.site-header');
    var $body = $('body');
    var $toggleButton = $('.menu__main__other');
    var _y = 0;
    var _activeClass = '-active';
    var _scrolledClass = '-scrolled';
    var _isOpen = false;
    $btn.on('click', function () {
        if (_isOpen) {
            _isOpen = false;
            $btn.removeClass(_activeClass);
            $nav.removeClass(_activeClass);
            $body.removeClass(_activeClass);
        }
        else {
            _isOpen = true;
            $btn.addClass(_activeClass);
            $nav.addClass(_activeClass);
            $body.addClass(_activeClass);
        }
    });
    $('.modal').fancybox();
    $toggleButton.on('click', function () {
        $(this).toggleClass(_activeClass);
        $(this).parent().find('.menu__main__other__list').toggleClass('open');
    });
});
// $(function() {
// });
// の書き方
// ↓↓↓↓↓
$(function () {
});
